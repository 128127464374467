import Auth from './../auth/auth';

export default function axiosHandler(error: any) {
    if (error.response) {
        const { status, data } = error.response;

        if (status === 401) {
            Auth.signout();
            window.location.href = "/authentication";
            return;
        }

        if (data?.status === 406) {
            Auth.signout();
            window.location.href = "/authentication";
            return;
        }

    } else {
        console.error('An unexpected error occurred:', error.message || error);
    }
    return;
}