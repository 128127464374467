
export default async function getCache(link: string) {
    const body = await fetch(link, { cache: "force-cache" })
    return await body.text();
}

export async function getLinkData(link: any){
    if (localStorage.getItem('serverType') === 'offline') {
        const localServerUrl = localStorage.getItem('localServerUrl');
        const localServerUrlType = localStorage.getItem('localServerUrlType');
        return await fetch(localServerUrlType + "://" + localServerUrl + "/get?url=" + link);
    }
    return await fetch(link);
}



