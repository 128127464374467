import { useEffect, useCallback } from "react";

const useAutoSizeTextArea = (
    textAreaRef: HTMLTextAreaElement | null,
    value: string
) => {
    const adjustHeight = useCallback(() => {
        if (textAreaRef) {
            textAreaRef.style.height = "0px";
            const borderTop = parseInt(getComputedStyle(textAreaRef).borderTopWidth, 10);
            const borderBottom = parseInt(getComputedStyle(textAreaRef).borderBottomWidth, 10);
            const height = textAreaRef.scrollHeight + borderTop + borderBottom;
            textAreaRef.style.height = `${Math.max(100, height)}px`;
        }
    }, [textAreaRef]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            adjustHeight();
        }, 0);

        return () => clearTimeout(timeoutId);
    }, [value, adjustHeight]);

    useEffect(() => {
        const handleResize = () => {
            adjustHeight();
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [adjustHeight]);

    return adjustHeight;
};

export default useAutoSizeTextArea;