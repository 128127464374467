import React, { useContext, useEffect, useState } from 'react'
import './NavBar.css'
import { ExamData, ExamDetails } from '../view/Private/Exam/context';
import { Button, Image, OverlayTrigger, Popover, ProgressBar, Tooltip } from 'react-bootstrap';
import { faCircleExclamation, faExclamation, faSkullCrossbones, faTriangleExclamation, faInfoCircle, faAdd, faSubtract, faSquarePlus, faSquareMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EndExamConfirmation from './Modal/EndExamConfirmation';
import LiveExam from '../Services/liveexam.services';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import InfoMadal from './Modal/InfoMadal';
import { useInterval } from 'usehooks-ts'
import { zoomIn, zoomOut } from '../helpers/zoominout';
import UserInfoModal from './Modal/UserInfoModal';


export default function NavBar() {

    const [showModal, setShowModal] = useState<boolean>(false)
    const [time, setTime] = useState<any>(new Date());
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
    const [isHovering, setIsHovering] = useState<boolean>(false);

    const [userIsBlocked, setUserIsBlocked] = useState<boolean>(false);



    const [seconds, setSeconds] = useState(9999);


    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                handleExamSubmission()
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });


    useInterval(
        () => {
            getUserExamLeftTime()
        },
        10000
    )


    const examDetails: any = useContext(ExamDetails);
    const examData: any = useContext(ExamData);

    const navigate = useNavigate();

    const [userInfo, setUserInfo] = useState<any>({
        student: {
            image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWRA198mFf-lgy73MskQmkQHszd5er5Th3XTiQq2vtYfgloZEAaFxBxeSz1tmae3ZelvU&usqp=CAU",
            name: "",
            first_name: ""
        }
    })

    const getUserInfo = async () => {
        await LiveExam.getUserInfo().then(res => {
            if (res.status == 200) {
                setUserInfo(res.data.data)
                setSeconds(res.data.data.remainingTime);
            }
        })
    }

    const handleExamSubmission = async () => {
        await LiveExam.setExamStatusCompleted().then(res => {
            if (res.status == 200) {
                toast.success("Exam Submitted")
                localStorage.removeItem("authKey");
                localStorage.removeItem("liveToken");
                navigate("/exam_submitted");
            }
        }).catch(e => {
            console.error(e);
        })
    }

    useEffect(() => {
        getUserInfo();
    }, [])


    const getUserExamLeftTime = async () => {
        await LiveExam.checkUserRemainingTime(seconds).then(res => {
            if (res.status == 200) {
                if (res.data.data.update_required === true) {
                    setSeconds(res.data.data.user_time);
                }
                else {
                    console.log("Time in sync");
                }

                if(res.data.data.blocked === true) {
                    toast.error("You have been Blocked from the Exam! Contact Admin");
                    sessionStorage.removeItem("liveToken")
                    localStorage.removeItem("authKey")
                    navigate("/authentication")
                }
            }
        }).catch(e=>{
            if(e.response.status === 410){
                toast.error("You have been Blocked from the Exam! Contact Admin");
                sessionStorage.removeItem("liveToken")
                localStorage.removeItem("authKey")
                navigate("/authentication")
            }
        })
    }

    const handleMouseOver = () => {
        setIsHovering(true);
    }

    const handleMouseOut = () => {
        setIsHovering(false);
    }

    const popover = (
        <div className="popover-text-user-data">
        <UserInfoModal />
        </div>
    );


    // useEffect(() => {
    //     let syncClock = setInterval(() => {
    //         getUserExamLeftTime();
    //     }, 5000)
    //     return () => {
    //         clearInterval(syncClock);
    //     };
    // });





    return (
        <>
            <div className="xen-top-nav noselect shadow-none">
                <div className='d-flex justify-content-between align-items-center'>
                    <div className="d-flex justify-content-start align-items-center">
                    <div>
                        {examDetails && examDetails.examDetail &&
                            <img className="dcm-client-icon me-3" src={examDetails.examDetail.icon} />
                        }
                    </div>
                        <div>
                            <div>
                            <span className="badge breathing-button bg-danger">Live</span>
                            <span className="ms-2 me-1">
                            {

                                seconds < 900 ?
                                    <b className='text-danger h6 fw-bold'>Time Left: {Math.floor(seconds / 60)} min : {seconds - Math.floor(seconds / 60) * 60} sec  <FontAwesomeIcon icon={faCircleExclamation} className="ms-1" /> </b>
                                    : 
                                    seconds < 1800 ?
                                    <b className='h6 text-info fw-bold'>Time Left: {Math.floor(seconds / 60)} min : {seconds - Math.floor(seconds / 60) * 60} sec </b>
                                    :
                                    <b className='h6 text-success fw-bold'>Time Left: {Math.floor(seconds / 60)} min : {seconds - Math.floor(seconds / 60) * 60} sec </b>
                            }
                            {/* {
                            } */}
                            </span>
                            </div>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faInfoCircle} onClick={() => setShowInfoModal(true)} />
                        </div>
                    </div>
                    <div>
                        <h5 className='noselect'>{examDetails?.examDetail?.examName || "Loading.."}</h5>
                    </div>
                    <div className="fs-6 ms-3">
                            <FontAwesomeIcon icon={faSquareMinus} onClick={zoomOut} className="dcm-link-cursor" /> A <FontAwesomeIcon icon={faSquarePlus} onClick={zoomIn} className="dcm-link-cursor" />
                        </div>
                    
                    <div >
                        <div className="ms-3">
                            <div className="d-flex justify-content-end align-items-center">
                                <div className="d-lg-flex d-md-block me-4">
                                    <OverlayTrigger
                                        placement="left"
                                        overlay={<Tooltip id="tooltip-left">{popover}</Tooltip>}
                                    >
                                        {({ ref, ...triggerHandler }) => (
                                            <Button
                                                variant="transparent"
                                                {...triggerHandler}
                                                className="d-inline-flex align-items-center"
                                            >
                                                <Image src={userInfo && userInfo.student && userInfo.student.image} ref={ref} roundedCircle className="xen-dash-logo ml-4" style={{ height: "48px", width: "48px" }} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} />
                                            </Button>
                                        )}
                                    </OverlayTrigger>
                                </div>

                              

                                <Button variant="danger" size="sm" onClick={() => { setShowModal(true) }} className="me-3">
                                    <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
                                    Submit & End Exam
                                </Button>
                                {/* <Form.Select className="form-control" id="exampleFormControlSelect1">
                                        {examDetails?.examDetail.languages.map((language: any) => {
                                            return <option>{language.toUpperCase()}</option>;
                                        })}
                                    </Form.Select> */}
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <EndExamConfirmation show={showModal} setShow={setShowModal} onSuccess={handleExamSubmission} />
            <InfoMadal show={showInfoModal} setShow={setShowInfoModal} />

        </>
    )
}